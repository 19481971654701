<template>
  <main class="auth-page">
    <div class="auth-page__container">
      <UILogo class="auth-page__logo" />
      <slot />
    </div>
  </main>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
@import '@/styles/variables.scss';
.auth-page {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100dvh;
  padding: $base-layout-padding 0;
  background-image: url('/images/auth-page-bg.png');

  @media screen and (max-width: 1023px) {
    padding: 60px 16px;
    background-image: url('/images/login-page-image.png');
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__logo {
    left: 0;
    top: -$logo-height - calc($base-layout-padding / 2);
    transform: translateY(50%);
    position: absolute;
    color: $black-100;

    @media screen and (max-width: 1023px) {
      top: -$logo-height - calc($base-layout-padding / 2);
      left: 50%;
      transform: translateX(-50%);
      color: $gold-primary-30;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    border-radius: 32px;
    background: white;
    width: 100%;
    max-width: 1250px;
    padding: 40px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    flex-grow: 1;

    @media screen and (max-width: 1023px) {
      border-radius: 16px;
      padding: 16px;
    }
  }
}
</style>
